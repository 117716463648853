.notfound { 
  background-color: #fff; 
  color: #000;
  font-size: 110%;
  font-weight: bolder;
  line-height: 1.5;
  font-family: "Cairo", sans-serif;
}

.notfound-button {
  font-weight: 300;
  color: #000;
  font-size: 1.2em;
  font-weight: bolder;
  text-decoration: none;
  border: 1px solid #000;
  padding: .5em;
  border-radius: 3px;
  float: left;
  margin: 4em 0 0 -130px;
  left: 50%;
  position: relative;
  transition: all .3s linear;
}

.notfound-button:hover {
  background-color: #000;
  color: #c7bdbd;
}

.notfound-description {
  font-size: 2em;
  text-align: center;
  font-weight: bolder;
}

.notfound-title {
  text-align: center;
  font-size: 15em;
  font-weight: bolder;
}