/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
  directoin: rtl;
  font-family: "Cairo", sans-serif;
}
/* Custom Scroll Bar */
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
::-webkit-scrollbar-thumb {
    background-color: #df7c0c;
    border-radius: 4rem;
}
