.boy {
    background:#fff;
    color: #333;
    direction: rtl;
}

.wrapper {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.row {
    width: 100%;
    clear: both;
    overflow: auto;
    background-color: #fff;
    position: relative;
    padding: 0;
}

.row-features {
    position: relative;

}

.new-feature-slider {
    width: 100%;
    float: left;
    overflow: hidden;
    .feature-slide {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all .5s ease;
      .feature-slide-image {
        img {
          width: 100%;
          display: block;
        }
      }
      &.active {
        opacity: 1;
      }
    }
}
.fakeh1 {
  font-size: 30px;
}
.block-wrap {
    position: relative;
    width: 100%;
    &:before {
      content: "";
      width: 100%;
      padding-top: 100%;
      display: block;
    }
}

.w50 {
    width: 50%;
    float: left;
}

.h50:before {
    padding-top: 50%;
}

.block {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    box-sizing: border-box;
}

.content-centered {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    width: 100%;
    max-width: 25em;
    transform: translate(-50%,-50%);
}

.controls {
    box-sizing: border-box;
    width: 80px;
    height: 160px;
    position: absolute;
    z-index: 9999;
    border-right: solid 8px rgba(93,193,195,0.4);
    overflow: visible;
    .control {
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      display: block;
      background: #131212;
      cursor: pointer;
      transition: all .3s ease;
      position: relative;
      float: right;
      &:first-child {
        border-bottom: 1px solid rgb(255, 255, 255);
      }
      &.button-next {
        transform: rotate(180deg);
      }
      &:before,
      &:after {
        width: 20px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        transform-origin: left center;
        content: "";
        background-color: #ffffff;
        transition: all .3s ease;
      }
      &:before {
        transform: translate(-50%,-50%)   rotate(45deg);
      }
      &:after {
        transform: translate(-50%,-50%) rotate(-45deg);
      }
      &:hover {
        background-color: #5dc1c3;
        &:before,
        &:after {
          background-color: white;
        }
      }
    }
}

.new-feature-controls {
    bottom: 10px;
    right: 50%;
}
@media screen and (max-width: 700px) {
  .body {
      display: none;
      visibility: hidden;
  }
}