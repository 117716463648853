.map-section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}

.map-title {
  font-size: 32px;
  margin-bottom: 20px;
}

.map-container {
  width: 100%;
  height: 100%;
}

.map-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
